::-webkit-scrollbar {
    display: none;
}
*, *::after, *::before{
    box-sizing: border-box;
    -webkit-font-smoothing:antialiased;
}
html{
    scroll-behavior: smooth;
}
.page-body{
    min-height: 500px;
}
