@import '../mixin';
@import "../comman";

.page-background{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background: $gradient1;
  h1{
    margin: 0;
    text-align: center;
    font-size: 35px;
    color: $text-color;
    @include devices($p-tablet){
      font-size: 40px;
    }
  }
}
