
// We define the mixins for two major breakpoints mobile and tablet

// $breakpoint is the variable that can have several values

@mixin devices ($breakpoint) { //the name of the mixin is devices

    @media screen and (min-width: $breakpoint) {
      @content;
    }
}
