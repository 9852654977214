@import '../mixin';
@import "../comman";

.blogs-section{
  padding: 80px 0;
  .blogs-section-wrap{
    .content{
      .title{
        font-size: 50px;
        line-height: 55px;
        margin: 0 0 30px;
        text-align: center;
        background-image:$heading-color;
        background-clip: text;
        color: transparent;
      }
    }
    .list{
      .slick-slide div{
        padding: 15px 15px;
      }
      &-item{

        .block{
          text-align: left;
          background: $background-color;
          border-radius: 8px;
          .name{
            margin: 0;
            padding: 15px 0 10px;
            font-size: 20px;
            line-height: 28px;
            color: $primary-color;
          }
          .content{
            font-size: 16px;
            color: #000;
            margin: 0 0 15px;
          }
          .readMore-btn{
            font-size: 16px;
            color: #0a66c2;
          }
        }
      }
    }
    .moreBlogButton{
      text-align: center;
      margin: 50px 0 0;
    }
  }
}
