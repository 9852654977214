.site-header {
  background: #fff;
  position: sticky;
  z-index: 999;
  top: 0;
  box-shadow: 2px 2px 15px -3px rgba(0, 0, 0, 0.39);

  .desktop-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    @include devices($p-tablet) {
      display: flex;
    }

    .logo {
      img {
        width: 200px;
        height: auto;
      }
    }

    .main-navigation {
      .navigation-list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .navigation-list-item {
          margin: 0 15px;

          &:last-child {
            margin-right: 0;
          }

          .navigation-link {
            color: $primary-color;
            font-size: 18px;
            padding: 15px 0;
            display: inline-block;
            transition: all 0.3s ease-in-out;
            font-weight: bold;
            &:hover {
              color: $hover-color;
            }
            &.resume{
              padding: 8px 10px;
              background: #000;
              color: #fff;
              border-radius: 8px;
              &:hover{
                background:#8e6be9;
              }
            }
          }
          .account-link{
            svg{
              font-size: 20px;
              color: #566ffc;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .mobile-header {
    position: relative;
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include devices($p-tablet) {
      display: none;
    }

    .toggle-menu {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 35px;
        height: 35px;
        color: #b269dd;
      }
    }

    .logo {
      img {
        width: 170px;
      }
    }

    .mobile-navigation {
      position: absolute;
      left: -100%;
      top: 0;
      width: 100%;
      height: 100vh;
      transition: all 0.2s ease-in-out;
      z-index: 9999;
      background: rgba(0,0,0,0.35);
      .mobile-navigation-wrapper{
        height: 100vh;
        background: #1c1c1c;
        width: 80%;
        overflow: auto;
      }
      &.open {
        left: 0;
      }

      .close-button {
        margin: 20px 20px 30px;
        height: 30px;
        width: 30px;
        fill: $text-color;
      }

      .navigation-list {
        padding: 0 20px 20px;

        .navigation-list-item {
          margin: 0 0 15px;
          border-bottom: 1px solid white;
          padding: 0 0 15px;
          &:last-child{
            border: none;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .navigation-link {
            color:#b269dd;
            font-size: 20px;
            display: inline-block;
            font-weight: bold;
          }
        }
      }
      .social-section{
        padding: 0 20px 20px;
        .social-title{
          color: $text-color;
          margin: 0 0 20px;
          font-size: 22px;
          line-height:24px;
        }
        .social-list{
          &-item{
            margin: 0 0 20px;
            &:last-child{
              margin: 0;
            }
            .link{
              color: #b269dd;
              font-size: 20px;
              &.icon{
                margin-right: 20px;
                svg{
                  font-size: 22px;
                }
                &:last-child{
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  #scrollbar {
    width: 100%;
    height: 8px;
    padding: 0;
    margin: 0 0 0 auto;
    left: 0;
    top: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 999;
  }

  #scrollbar #bar {
    width: 0;
    height: 6px !important;
    background-color: #000;
  }
}

.scrollUpButton{
  display: none;
  transition: all 0.3s ease-in;
  &.visible{
    display: inline-block;
  }
  position: fixed;
  bottom: 50px;
  right: 30px;
  height: 50px;
  width: 50px;
  padding: 0;
  margin: 0;
  outline: none;
  border: 2px solid #9e6be4;
  border-radius: 100%;
  background: black;
  cursor: pointer;
  z-index: 9;
}
.scrollUpButton svg{
  font-size: 25px;
  color: #fff;
}
