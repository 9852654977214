@import '../mixin';
@import "../comman";

.account{
  background: $gradient1;
  padding: 30px 0;
  @include devices($p-tablet){
    padding: 80px 0;
  }
  .login-section,.create-user-section{
    backdrop-filter: blur(60px);
    background: #ffffff40;
    padding: 20px 15px;
    border-radius: 10px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    height: max-content;
    @include devices($p-tablet){
      width: calc(50% - 20px);
      margin: 0 auto;
    }
    h3{
      color: white;
      font-size: 35px;
      line-height: 38px;
      font-weight: bold;
      margin: 0 0 20px;
      text-align: center;
    }
    .form{
      .input-field{
        margin: 0 0 15px;
        label{
          font-size: 14px;
          color: #fff;
          margin: 0 0 10px;
          display: inline-block;
        }
        input{
          font-size: 16px;
          padding: 10px 10px;
          border-radius: 8px;
          border: none;
          width: 100%;
          &::placeholder{
            font-size: 14px;
            color: #ABABAB;
          }
        }
      }
      .button-wrap{
        margin: 15px 0 0;
        .button{
          display: inline-block;
          padding: 10px 30px;
          color: #fff;
          border-radius: 40px;
          font-size: 16px;
          border: none;
          background: #000;
          transition: background 0.3s ease-in;
          cursor: pointer;
          &:hover{
            background: #8e6be9;
          }
        }

      }
      .separator{
        font-size: 16px;
        color: #fff;
        margin: 20px 10px;
        position: relative;
        text-align: center;
        &:before{
          content: '';
          position: absolute;
          background: #fff;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          width: 40%;
        }
        &:after{
          content: '';
          position: absolute;
          background: #fff;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          width: 40%;
        }
      }
    }
    .google-method{
      display: flex;
      flex-direction: column;
      align-items: center;
      .button{
        display: flex;
        align-items: center;
        padding: 10px 30px;
        color: #fff;
        border-radius: 40px;
        font-size: 16px;
        border: none;
        background: #000;
        transition: background 0.3s ease-in;
        cursor: pointer;
        width: max-content;
        &:hover{
          background: #8e6be9;

        }
        svg{
          margin-right: 8px;
          font-size: 20px;
        }
      }
    }
    .account-link{
      color: #fff;
      font-size: 16px;
      display: inline-block;
      margin: 15px 0 0;
      span{
        font-weight: bold;
      }
    }
  }
  .error{
    font-size: 14px;
    margin: 15px 0 0;
    color: #ffffff;
    padding: 5px 8px;
    background: red;
    width: max-content;
    border-radius: 8px;
  }
}
