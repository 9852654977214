@import '../mixin';
@import "../comman";

.hero-section{
  background: $gradient1;
  position: relative;
  .hero-section-wrapper {
    padding: 80px 0;
    @include devices($p-tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 95px 0;
    }

    .image {
      margin: 0 0 30px;
      padding: 0 20px;
      text-align: center;
      @include devices($p-tablet) {
        width: calc(40% - 40px);
        margin: 0;
      }

      img {
        border-radius: 100%;
        z-index: 1;
        position: relative;
      }
    }

    .info {
      @include devices($p-tablet) {
        width: calc(60% - 40px);
      }

      .name {
        font-size: 62px;
        line-height: 55px;
        margin: 0 0 30px;
        color: #fff;
      }

      .designation {
        line-height: 28px;
        margin: 0 0 30px;
        font-size: 22px;
        color: #fff;
        max-width: 450px;
      }

      .resumeButton {
        display: inline-block;
        padding: 15px 30px;
        color: #fff;
        border-radius: 40px;
        font-size: 20px;
        background: #000;
        transition: background 0.3s ease-in;
        outline: none;
        border: none;
        cursor: pointer;
        z-index: 1;
        position: relative;

        &:hover {
          background: #592099;

        }

        span {
          display: flex;
          align-items: center;
        }

        svg {
          margin-left: 10px;
        }
      }
    }
  }
  .embed-animated-text-background {
    bottom: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 120px;
    font-weight: 700;
    left: 0;
    line-height: .9;
    opacity: .08;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    @include devices($p-tablet){
      font-size: 150px;
    }
    @include devices($l-tablet){
      font-size: 230px;
    }
  }
  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%); /* Adjust the value to change the amount of translation */
    }
  }
  .animate-text{
    animation: slideUp 21s linear infinite;
    font-family: "JetBrains Mono",monospace;
    filter: blur(0px);
    margin: 0 0 20px;
    word-break: break-word;
    @include devices($l-tablet){
      word-break: unset;
    }
  }
}
