@import '../mixin';
@import "../comman";

.capability-section{
  background: $background-color;
  padding: 80px 0;
  .capability-section-wrap{
    .content{
      .title{
        font-size: 50px;
        line-height: 55px;
        margin: 0 0 30px;
        text-align: center;
        background-image:$heading-color;
        background-clip: text;
        color: transparent;
      }
    }
    .list{
      @include devices($p-tablet){
        display: flex;
        justify-content: space-between;
      }
      &-item{
        margin: 0 0 15px;
        @include devices($p-tablet){
          width: calc(33.33% - 20px);
        }
        .block{
          text-align: center;
          background: $primary-color;
          border-radius: 8px;
          img{
            width: 100%;
          }
          .name{
            margin: 0;
            padding: 15px 8px 15px;
            font-size: 22px;
            line-height: 28px;
            word-wrap: break-word;
            color: $text-color;
          }
        }
      }
    }
  }
}
