@import '../mixin';
@import "../comman";

.page-background-heading{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  background: #000;
  h1{
    margin: 0;
    text-align: left;
    font-size: 40px;
    color: $text-color;
  }
}
