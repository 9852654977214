.site-footer{
    background: $primary-color;
    //background: linear-gradient(90deg, #566FFC, #F865C6);
    padding: 50px 0;
  .footer-list{
    margin: 0 0 50px;
    @include devices($p-tablet){
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }
    .footer-column{
      margin: 0 0 30px;
      @include devices($p-tablet){
        width: calc(50% - 20px);
      }
      @include devices($l-tablet){
        width: calc(33.33% - 20px);
      }
      @include devices($laptop){
        width: calc(25% - 20px);
      }
      &:last-child{
        margin: 0;
      }
      .footer-title{
        margin: 0 0 20px;
        font-size: 24px;
        font-weight: bold;
        color: $text-color;
      }
      .footer-sublist{
        .footer-sublist-item{
          margin: 0 0 15px;
          &:last-child{
            margin: 0;
          }
          &.logo{
            margin: 0 0 30px;
            img{
              width: 170px;
            }
          }
          .navigation-link{
            display: inline-block;
          }
          a,span{
            font-size: 18px;
            color: $text-color;
            display: flex;
            align-items: center;
            line-height: 24px;
            transition: color 0.3s ease-in;
            &:hover{
              color: #8e6be9;
            }
            svg{
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
    .copyright{
      p{
        text-align: center;
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        color: $text-color;
      }
    }
}
