@import '../mixin';
@import "../comman";

.about-section{
  padding: 80px 0;
  background: $background-color;
  .about-section-wrap{
    @include devices($p-tablet){
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .image{
      @include devices($p-tablet){
        width: calc(40% - 40px);
      }
      img{
        border-radius: 15px;
      }
    }
    .info{
      margin: 0 0 50px;
      @include devices($p-tablet){
        width: calc(60% - 40px);
        margin: 0;
      }
      .name{
        font-size: 50px;
        line-height: 55px;
        margin: 0 0 30px;
        background-image:$heading-color;
        background-clip: text;
        color: transparent;
      }
      .description{
        line-height: 28px;
        margin: 0 0 30px;
        font-size: 20px;
      }
    }
  }
}
