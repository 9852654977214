@import '../mixin';
@import "../comman";

.skill-section{
  padding: 80px 0;
  .skill-section-wrap{
    .content {
      text-align: center;

      .title {
        font-size: 50px;
        line-height: 55px;
        margin: 0 0 30px;
        background-image:$heading-color;
        background-clip: text;
        color: transparent;
      }
    }
    .list{
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        &-item{
          width: calc(50% - 20px);
          margin: 0 10px 20px;
          @include devices($p-tablet){
            width: calc(33.33% - 20px);
          }
          @include devices($l-tablet){
            width: calc(16.66% - 20px);
          }
          &-content{
            background: $background-color;
            border-radius: 15px;
            padding: 15px 20px;
            text-align: center;
            transition: all 0.3s ease-in;
            &:hover{
              box-shadow: 2px 2px 15px -3px rgba(0, 0, 0, 0.39);
              img{
                transform: scale(1.0);
              }
            }
            img{
              transform: scale(0.8);
              transition: all 0.3s ease-in;
            }
            .name{
              margin-bottom: 10px;
              font-size: 22px;
              line-height: 28px;
              word-wrap: break-word;
            }
          }
        }
      }
  }
}
