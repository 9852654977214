@import '../mixin';
@import "../comman";

.btn{
  display: inline-block;
  padding: 15px 30px;
  color: #fff;
  border-radius: 40px;
  font-size: 20px;
  background: #000;
  transition: background 0.3s ease-in;
  &:hover{
    background: #8e6be9;

  }
  span{
    display: flex;
    align-items: center;
  }
  svg{
    margin-left: 10px;
  }
}
