@import '../mixin';
@import "../comman";

.reviewListComponent{
  padding: 80px 0;
  .list{
    &-item{
      background: #ecf8ff;
      padding: 10px 15px;
      border-radius: 10px;
      position: relative;
      margin: 0 0 80px;
      &:last-child{
        margin: 0;
      }
      img{
        height: 120px;
        width: 120px;
        border-radius: 100%;
        position: absolute;
        left: 100px;
        transform: translate(-50%,-50%);
        border: 10px solid #ecf8ff;
      }
      .profile{
        display: flex;
        align-items: center;
        margin: 80px 0 0;
        padding: 0;
        .name{
          margin: 0;
          font-size: 22px;
          line-height: 28px;
        }
        .link{
          margin-left: 10px;
          display: inline-block;
          line-height: 0;
          svg{
            color: #0a66c2;
            font-size: 20px;
          }
        }
      }
      .designation{
        font-size: 16px;
        line-height: 22px;
      }
      .review{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
