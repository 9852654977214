@import '../mixin';
@import "../comman";

.testimonial-section{
  padding: 80px 0;
  .testimonial-section-wrap{
    .content{
      .title{
        font-size: 50px;
        line-height: 55px;
        margin: 0 0 30px;
        text-align: center;
        background-image:$heading-color;
        background-clip: text;
        color: transparent;
      }
    }
    .list{
      .slick-slide {
        display: inline-block;
        margin-top: 45px;
        div{
          position: relative;
          background: $background-color;
          border-radius: 10px;
          padding: 10px 15px;
          margin: 0 15px;
          img{
            position: absolute;
            height: 100px;
            width: 100px;
            border-radius: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 8px solid $background-color;
          }
          .profile{
            display: flex;
            align-items: center;
            margin: 55px 0 0;
            padding: 0;
            .name{
              margin: 0;
              font-size: 22px;
              line-height: 28px;
            }
            .link{
              margin-left: 10px;
              display: inline-block;
              line-height: 0;
              svg{
                color: #0a66c2;
                font-size: 20px;
              }
            }
          }

          .position{
            font-size: 16px;
            line-height: 22px;
          }
          .description{
            font-size: 16px;
            line-height: 24px;
          }

        }
      }
    }
    .moreReviewsButton{
      margin: 50px 0 0;
      text-align: center;
    }
  }
}
