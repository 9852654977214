@import '../mixin';
@import "../comman";

.activity-section{
  padding: 80px 0;
  background: $background-color;
  .activity-section-wrap{
    .content{
      .title{
        font-size: 50px;
        line-height: 55px;
        margin: 0 0 30px;
        text-align: center;
        background-image:$heading-color;
        background-clip: text;
        color: transparent;
      }
    }
    .list{
      .slick-slide {
        div{
          padding: 15px 15px;
        }
        .list-item{
          padding: 5px 0 5px 5px;;
          background: #bfbfbfe6;
          border-radius: 5px;
          .post{
            position: relative;
            overflow: hidden;
            padding-top: 100%;
            iframe{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 0;
            }
          }
        }

      }
    }
  }
}
