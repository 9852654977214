//font

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//variables

$gradient1:linear-gradient(136deg, rgb(70, 141, 224) 8.41%, rgb(140, 95, 244) 71.04%);
$gradient2:linear-gradient(139deg, rgb(97, 217, 243) 16.31%, rgb(97, 201, 243) 28.13%, rgb(24, 99, 188) 74.2%);
$gradient3:linear-gradient(138deg, rgb(125, 225, 207) 12.76%, rgb(24, 98, 209) 65.6%);
$text-color: #ffffff;
$primary-color: #000000;
$hover-color:#b269dd;
$primary-font: 'Lato', sans-serif;
$heading-color:linear-gradient(170deg, #566FFC, #F865C6);
$background-color: #ECF8FFFF;

//breakpoints

$m-mobile: 480px;
$l-mobile: 600px;
$p-tablet: 768px;
$l-tablet: 1024px;
$laptop: 1280px;
$desktop: 1824px;


//common styling

body{
  font-family: $primary-font;
  font-size: 18px;
   &:has(.mobile-navigation.open){
      overflow: hidden;
   }
}

.container{
  max-width: 1300px;
  padding: 0 20px;
  margin: 0 auto;
}

a{
  text-decoration: none;
}

ul,ol{
  list-style: none;
  padding: 0;
  margin: 0;
}
img{
  max-width: 100%;
}
