@import '../mixin';
@import "../comman";

.notFoundSection{
  padding: 80px 0;
  text-align: center;
  img{
    width: 350px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  p{
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 30px;
  }
}
