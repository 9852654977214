@import '../mixin';
@import "../comman";

.search-div{
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 0;
  text-align: right;
  .input-field{
    position: relative;
    #search-field{
      width: 100%;
      font-size: 16px;
      padding: 10px 35px 10px 10px;
      border-radius: 8px;
      border:1px solid #ABABAB;
      &::placeholder{
        font-size: 14px;
        color: #ABABAB;
      }
    }
    svg{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }

  }
  button{
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    svg{
      vertical-align: middle;
    }
  }
}
.message{
  text-align: center;
  font-size: 16px;
}
.blogListComponent{
  padding: 50px 0 80px;
  .list{
    @include devices($p-tablet){
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
    }
    .list-item{
      margin: 0 0 40px;
      &:hover{
        .blog-content{
          box-shadow: 2px 2px 15px -3px rgba(0, 0, 0, 0.39);
        }
      }
      @include devices($p-tablet){
        width: calc(50% - 20px);
        margin: 0 10px 40px;
      }
      @include devices($l-tablet){
        width: calc(33.33% - 20px);
      }
      .blog-content{
        display: inline-block;
        padding: 15px 15px;
        border-radius: 15px;
        background: $background-color;
        height: 100%;
        transition: all 0.3s ease-in;
        .name{
          margin: 0;
          padding: 15px 0 10px;
          font-size: 20px;
          line-height: 28px;
          color: $primary-color;
        }
        .content{
          font-size: 16px;
          color: #000;
          margin: 0 0 15px;
        }
        .readMore-btn{
          font-size: 16px;
          color: #0a66c2;
        }
      }
    }
  }
}
